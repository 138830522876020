var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"sc-page-sheet"},[_c('v-row',{attrs:{"align":"center","align-content":"center"}},[_c('i18n',{staticClass:"ml-4 primary--text text-uppercase text-h4",attrs:{"path":'menu.'+_vm.$route.name.toLowerCase()}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","clearable":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadData.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.loadData(1)}],"click:append":_vm.loadData,"click:clear":function($event){return _vm.loadData(1)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"error ma-4",attrs:{"icon":"","dark":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata'),"options":_vm.pagination,"page":_vm.currentPage,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showServs(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t("icons.services")))])],1),(item.card)?_c('v-btn',{attrs:{"icon":"","to":("/?c=" + (item.card))}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-play")])],1):_vm._e()]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('v-avatar',[_c('v-img',{attrs:{"alt":"Avatar","src":_vm.$api.publicImgLink(item.img),"lazy-src":"/img/default-avatar.jpg"}})],1):_vm._e()]}},{key:"item.frozen",fn:function(ref){
var item = ref.item;
return [(item.profile && item.profile.frozenfrom)?_c('v-chip',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(item.profile.frozenfrom))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('sc-record-status',{attrs:{"status":item.status,"statusi":item.statusi}})]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"pink my-1",attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.edit(null)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]},proxy:true}])}),_c('sc-client-dialog',{attrs:{"item":_vm.item},on:{"save":_vm.onsave,"remove":_vm.onremove},model:{value:(_vm.d_edit),callback:function ($$v) {_vm.d_edit=$$v},expression:"d_edit"}}),_c('sc-client-services-dialog',{attrs:{"items":_vm.services,"canProlong":""},model:{value:(_vm.d_clservs),callback:function ($$v) {_vm.d_clservs=$$v},expression:"d_clservs"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }